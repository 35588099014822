.lead {
  font-size: 150%;
  font-weight: 300; }

.audio {
  overflow: hidden;
  position: relative;
  margin: 0.5rem; }

audio {
  width: 100%;
  padding: 1rem; }
